angular.module('LeasePilot').controller('CustomFileUploadController', [
  '$scope',
  '$interpolate',
  'ApiService',
  function(
    $scope,
    $interpolate,
    ApiService,
  ) {
    var docFileType = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    var imgFileType = ['image/*'];
    var pdfFileType = ['application/pdf', 'application/x-pdf'];
    var allFileTypes = _.union(docFileType, imgFileType, pdfFileType);

    $scope.supportedFiles = allFileTypes.join();

    $scope.uploadFile = async function(ref, file) {
      if (!file || !$scope.lease.id) {
        return;
      }

      $scope.safeApply(() => {
        $scope.uploading = true;
      });

      let images = null;
      
      if (/^image\//.test(file.type)) {
        images = await window.ImageHelper.getImagesFromFile(file);
      } else if (docFileType.indexOf(file.type) !== -1) {
        const pdfBits = await ApiService.documentToPdf(file);
        const pdfFile = new File([pdfBits.data], file.name, {
          type: 'application/pdf',
          lastModified: Date.now(),
        });
        
        images = await window.ImageHelper.getImagesFromPDF(pdfFile);
      } else if (pdfFileType.indexOf(file.type) !== -1) {
        images = await window.ImageHelper.getImagesFromPDF(file);
      }

      for (let i = 0; i < images.length; i++) {
        images[i] = await window.ImageHelper.resizeImage(images[i]);
      }

      $scope.safeApply(() => {
        _.set($scope, ref, {
          images,
          filename: file.name,
        });
  
        $scope.update();
        $scope.updateCurrent(ref);
        $scope.uploading = false;
      });
    };

    $scope.removeFile = function(ref, event) {
      if (!$scope.lease.id) {
        return;
      }

      if (ref.indexOf("$index") !== -1) {
        const interpolateFn = $interpolate(ref);
        const scope = angular.element(event.target).scope();
        ref = interpolateFn(scope);
      }

      _.set($scope, ref, null);
      $scope.update();
      $scope.updateCurrent(ref);
      $scope.uploading = false;
    };
  },
]);
